import "./src/styles/global.css"

export const onClientEntry = () => {
  document.addEventListener("snipcart.ready", () => {
    const cartItems = Snipcart.store.getState().cart.items.items
    const hasLocalDeliveryProduct = cartItems.some(item =>
      item.customFields.some(
        field => field.name === "Local delivery only" && field.value === "true"
      )
    )

    if (hasLocalDeliveryProduct) {
      Snipcart.store.dispatch("shipping.remove_rates")
      Snipcart.store.dispatch("shipping.fetch", {
        method: "FixedRate",
        cost: 5.0,
        guaranteedDaysToDelivery: 1,
        name: "Local Delivery",
      })
    }
  })
}
